/* @import url('https://fonts.googleapis.com/css2?family=Suranna&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


@font-face {
  font-family:Guttie;
  src: url("../src/Guttie.ttf");
}

body{
  width:100vw;
}
*,body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 25px;
}
.Terms{
  font-family: 'Roboto', sans-serif !important;

}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

body::-webkit-scrollbar {
  width: 0;
  background-color: transparent;

}

body::-webkit-scrollbar-thumb {
  background-color:transparent;
}
